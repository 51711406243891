<template>
  <div>
    <CRow>
      <CCol sm="12">
        <h3>Agregar nueva reparación</h3>
        <br>
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="5">
        <CCard>
          <CForm v-bind:wasValidated="this.validationError">
            <CCardBody>
                <CToaster v-if="errores.length" :autohide="3000">
                  <template v-for="error in errores">
                    <CToast
                      color="danger"
                      :key="'error' + error"
                      :show="true"
                      header="Error de validación"
                    >
                      {{error}}
                    </CToast>
                  </template>
                </CToaster>
              <p>Selecciona una marca</p>
              <CButtonToolbar class="buttonToolbar">
                <template v-for="marca in marcas">
                  <CButton :key="marca.id" v-on:click="selectMarca(marca.id)" :color="selectedMarca == marca.id ? 'primary' : 'outline-primary'">{{marca.name}}</CButton>
                </template>
              </CButtonToolbar>
              <div style="margin-top: 20px;" v-if="selectedMarca">
              <CSelect ref="modelo"
                label="Modelo"
                :value.sync="reparacion.modelo_id"
                :options="generateModelosSelect()"
                placeholder="Selecciona un modelo"
                autocomplete="modelo"
                description="Si tu modelo no se muestra acá, deberías agregarlo al listado de Modelos"
                required
              />
              <CSelect ref="tipo_reparacion"
                label="Tipo de reparación"
                :value.sync="reparacion.name"
                :options="generateProblemasSelect()"
                placeholder="Selecciona una opción"
                autocomplete="modelo"
                description="Si la opción que estabas buscando no esta diponible, solicita a los administradores que agreguen esta opción."
                required
              />
              <CInput v-model="reparacion.description"
                type="text"
                description="Tip: ¿Qué incluye la reparación?"
                autocomplete="reparacionDesc"
                label="Descripción"
                placeholder=""
                required
              />
              <CInput v-model="reparacion.price"
                type="number"
                description="Solo números"
                autocomplete="reparacionPrice"
                label="Precio"
                placeholder=""
                required
              />
              <CInputCheckbox
                    :key="this.reparacion.stock"
                    :label="'En Stock'"
                    :value="this.reparacion.stock"
                    :custom="true"
                    :checked.sync="reparacion.stock"
                  />
              </div>
            </CCardBody>
            <CCardFooter v-if="selectedMarca">
              <CButtonGroup>
                <CButton v-on:click.prevent="triggerUpdateReparacion()" type="submit" color="success">Guardar cambios</CButton>
                <CButton v-on:click="triggerBack()" color="info">Volver</CButton>
              </CButtonGroup>
            </CCardFooter>
          </CForm>
        </CCard>
      </CCol>
      <CCol v-if="problemas.length > 0" sm="6">
        <div class="list">
          <div class="list__table">
            <CDataTable :alignVertical="true"
              :items="problemasTable"
              :fields="fields"
              :items-per-page="200"
              :columnFilter="false"
              :tableFilter="false"
              :sorterValue="{ asc: true }"
              :sorter='{ external: false, resetable: true }'
              pagination
            >
            </CDataTable>
          </div>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
const Swal = require('sweetalert2');
const problemasService = require('../../services/problemas.service');
const modelosService = require('../../services/modelos.service');
const marcasService = require('../../services/marcas.service');
const reparacionesService = require('../../services/reparaciones.service');

export default {
  name: 'nuevaReparacion',
  data() {
    return {
      fields: this.generateFields(),
      reparacion: {
        name: '',
        description: '',
        modelo_name: '',
        modelo_id: '',
        price: '',
        stock: true,
        email_template: 'generico',
        usuario_id: this.$store.state.user.data.id,
      },
      modelo: {},
      marcas: [{}],
      problemas: [{}],
      problemasTable: [],
      modelos: [{}],
      errores: [],
      selectedMarca: false,
      validationError: false,
      isLoading: true,
    }
  },
  mounted() {
    this.getProblemas(this.$store.state.user.data.id);
    this.getModelos(this.$store.state.user.data.id);
    this.getMarcas(this.$store.state.user.data.id);
  },
  updated() {
    this.setupReparacion();
  },
  methods: {

    // API REQUESTS

    getProblemas: function() {
      problemasService.getProblemas(this.$store.state.user.data.id).then((response) => {
        // handle success
        let { problemas } = response.data
        this.problemas = problemas;
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
    },
    getModelos: function() {
      modelosService.getModelos(this.$store.state.user.data.id).then((response) => {
        // handle success
        this.modelos = response.data.modelos;
        this.isLoading = false;
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
    },
    getMarcas: function() {
      marcasService.getMarcas(this.$store.state.user.data.id).then((response) => {
        // handle success
        this.marcas = response.data.marcas;
        this.isLoading = false;
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
    },

    // FORM AND DATA CONFIG
    selectMarca(marca_id){
      this.selectedMarca = marca_id;

      this.generateModelosSelect();
      this.generateProblemasSelect();
      this.generateProblemasTable();

    },
    generateModelosSelect(){
      let select = [];
      this.modelos.forEach(modelo => {
        if(modelo.marca_id == this.selectedMarca)
          select.push({value: modelo.id, label: modelo.name})
        });
      return select;
    },
    generateProblemasSelect(){
      let select = [];
      this.problemas.forEach(problema => {
        if(problema.marca_id == this.selectedMarca)
          select.push({value: problema.reparacion_name, label: problema.reparacion_name})
        });
      return select;
    },
    generateProblemasTable(){
      this.problemasTable = [];
      this.problemas.forEach(problema => {
        if(problema.marca_id == this.selectedMarca){
          this.problemasTable.push(problema);
        }
      });
    },
    generateFields(){
      const fields = [{
        key: 'name' ,
        label: 'Problema',
        filter: false,
        sorter: false
      },{
        key: 'reparacion_name' ,
        label: 'Tipo de reparación',
        filter: false,
        sorter: false
      }];

      return fields;
    },
    getBadge (status) {
      return status === true ? 'success'
          : status === false ? 'danger' : 'secondary'
    },
    parseStock (stock) {
      return stock === true ? 'En stock'
        : stock === false ? 'Sin stock' : ''
    },
    checkForm: function () {
      if (this.reparacion.name && this.reparacion.price) {
        return true;
      }

      this.errores = [];
      this.validationError = true;
      if (!this.reparacion.name) {
        this.errores.push('Nombre es obligatorio.');
      }
      if (!this.reparacion.price) {
        this.errores.push('Falta agregar un precio.');
      }
    },
    triggerBack(){
      this.$router.push('/reparaciones');
    },

    // REPARACION UPDATE / SEND TO API

    setupReparacion(){

      // Setup del nombre del modelo

      this.modelos.forEach((modelo) => {
        if(this.reparacion.modelo_id == modelo.id){
          this.reparacion.modelo_name = modelo.name;
        }
      });

      //Setup del tipo de respuesta

      switch (this.reparacion.name) {
        case 'Otro Problema':
          this.reparacion.email_template = 'otro-problema';
          this.reparacion.price = 0;
          break;
        case 'Se mojo': 
          this.reparacion.email_template = 'se-mojo';
          this.reparacion.price = 0;
          break;
        default:
          this.reparacion.email_template = 'generico';
      }

    },

    async triggerUpdateReparacion(){
      await Swal.fire({
        title: 'Guardar cambios',
        html: `Vas a dar de alta esta reparación. <br><br><strong>¿Estás seguro?</strong>`,
        showDenyButton: true,
        confirmButtonText: `Confirmar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(this.reparacion);
          reparacionesService.createReparacion(this.reparacion).then((response) => {
            console.log(response);
            Swal.fire('¡Operación realizada!', 'La reparacion fue actualizada', 'success');
          }).catch((error) => {
            // handle error
            console.log(error.response);
            Swal.fire('Hubo un error', error.response.data.msg, 'error')
          });
        } else if (result.isDenied) {
          Swal.fire('Operación cancelada', '', 'error');
        }
      });
    }
  }
}
</script>
