const axios = require('axios');


const problemasService = {

    getProblemas(usuario_id) {
        return axios.get(`${process.env.VUE_APP_API_URL}api/problemas?usuario_id=${usuario_id}`,{
            headers: {
            'x-token-clica': 'agenciaclica123'
            }
        });
    },
}

module.exports = problemasService;