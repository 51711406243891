const axios = require('axios');


const modelosService = {

    getModelos(usuario_id) {
        return axios.get(`${process.env.VUE_APP_API_URL}api/modelos/usuario/${usuario_id}`,{
            headers: {
            'x-token-clica': 'agenciaclica123'
            }
        });
    },

    createModelo(modelo) {
        return axios.post(`${process.env.VUE_APP_API_URL}api/modelos/`, modelo,
        {
            headers: {
            'x-token-clica': 'agenciaclica123'
            }
        });
    },

    updateModelo(modelo_id, modelo) {
        return axios.put(`${process.env.VUE_APP_API_URL}api/modelos/${modelo_id}`,modelo,{
            headers: {
                'x-token-clica': 'agenciaclica123'
            }
        });
    },

    deleteModelo(modelo_id) {
        return axios.delete(`${process.env.VUE_APP_API_URL}api/modelos/${modelo_id}`,{
            headers: {
                'x-token-clica': 'agenciaclica123'
            }
        });
    },
}

module.exports = modelosService;