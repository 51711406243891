const axios = require('axios');


const marcasService = {

    getMarcas(usuario_id) {
        console.log(usuario_id);
        return axios.get(`${process.env.VUE_APP_API_URL}api/marcas/?usuario_id=${usuario_id}`,{
            headers: {
            'x-token-clica': 'agenciaclica123'
            }
        });
    },
}

module.exports = marcasService;